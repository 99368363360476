<template>
    <div class="locked-page relative h-100 w-100">
        <div class="locked-page__container flex-column jc-c ai-fs h-100 w-100 relative">
            <div class="locked-page__content flex-column">
              <Typography variant="h3">This workstation section is locked</Typography>
              <Typography variant="h6" textWeight="500" textColor="rgba(12, 15, 74, 0.50)">
                Please upgrade to unlock the full benefits.
              </Typography>
              <section class="locked-page__wrapper flex-column gap-half">
                <div class="flex-row ai-c" v-for="(benefit, index) in benefits" :key="index">
                  <Icon iconName="blue-check-filled-icon.svg" iconHeight="24px" iconWidth="24px"/>
                  <Typography variant="p" textWeight="400">{{ benefit.label }}</Typography>
                </div>
              </section>
             <Button
                :isActive="true"
                buttonText="upgrade your account to a workstation"
                @handle-click="onHandleClick"/>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Icon,
    Typography,
    Button,
  },

  data() {
    return {
      benefits: [
        {
          label: 'Value one'
        },
        {
          label: 'Value two'
        },
        {
          label: 'Value three'
        },
      ],
    };
  },
});
</script>
<style lang="scss" scoped>
.locked-page {
    &::before {
      content: "";
      position: absolute;
      background: url(~@/assets/images/app-screenshot.jpg);
      box-shadow: 0px 0px 10px 0px rgba(13, 9, 34, 0.20);
      background-repeat: no-repeat;
      background-color: lightgray;
      opacity: .70;
      height: 470px;
      width: 756px;
      filter: blur(10px);
    }

    &__container {
        z-index: 2;
        padding-left: 5rem;
        box-sizing: border-box;
    }

    &__content {
        margin-top: -15rem;
    }

    &__wrapper {
      margin-bottom: 2rem;
    }
}
</style>
